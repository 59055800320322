@import "../../node_modules/bootstrap/dist/css/bootstrap.css";

:root {
  --color-primary: #336122;
  --color-secondary: #B364A9;
  --seleccio-hover: #e5ffdb;
  --seleccionat: #8fa387;
}

body, p, div {
  margin: 0;
  /* font-family:'Archivo','Mukta Mahee', sans-serif; */
  font-family:'IBM Plex Sans Condensed','Mukta Mahee', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align:left;
  font-weight: 400;
  font-size: 19px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  /* font-family: 'Archivo', sans-serif; */
  font-family:'IBM Plex Sans Condensed', sans-serif;
  font-weight: 700;
  text-align:left;
}

a {
  color: var(--color-primary);
}

a:hover {
  color: var(--seleccionat);
}


.maBoto{
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.menuSuperior .nav-item {
  padding-left: 20px;
  cursor: pointer;
  padding: 8px 10px;
}

.linkmenu {
  color: #000;
}

.linkmenu:hover {
  color: #000;
  text-decoration: none;
}

.titolBuscadorPortada {
  font-weight: 400;
}

.finestraFlotant {
  width: 100%;
  max-height: 90%;
  overflow: auto;
  position: fixed;
  background-color: #FFFF;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 2000;
  padding:10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #888888;
}

.finestraFlotant .botoTancar {
  text-align: right;  
}

.fonsFinestraFlotant {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #00000047;
  left: 0;
  top: 0;
  z-index: 1999;
}

.logoPeu {
  max-width: 50%;
}

.columnaPeuDreta p {
  text-align: right;
}


.numVersio {
  font-size: 10px;
  text-align: right;
  margin-top: 10px!important;
}

/* @media (max-width: 768px) {
  .col-md {
    margin-bottom: 25px;
}
} */

/*PORTADA*/
.fotoGranPortada {
  height: 400px;
  background-image: url("https://mobilitat.bergueda.cat/img/fotos/01.jpg");
  background-color: black;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; 
}

.iconesLiniaPortada div {
  width: 80px;
  text-align: center;
  display: inline-block;
  margin: 2px 5px 2px 0px;
}

.iconesLiniaPortada {
  margin-bottom: 10px;
}

.nomParadaPortada {
  margin:0;
}

.logoUniversitat {
  max-width: 200px;
}

.targetaPortada {
  margin-bottom: 25px;
}

.incidenciesActuals {
  padding: 5px 0px;
}



/* LINKS PEU*/

.linksDestacats li {
  list-style-type: none;
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}

.linksDestacats li {
  list-style-type: none;
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}
.linksDestacats a:hover {
  text-decoration: none;
}

.linksDestacats {
  padding: 20px 0px;
}

/*ICONES CARRUSSEL */

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
  position: absolute;
  left: -30px;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
  position: absolute;
  right: -30px;
}

.carousel-indicators {
  display: none;
}

@media (max-width: 768px) {
  .carousel-control-prev-icon {
    left: 0;
  }

  .carousel-control-next-icon {
    right: 0px;
  }
}

/* .fotoGran {
  border: 10px solid transparent;
  border-image: url("https://mobilitat.bergueda.cat/img/css/vora-superior.png") 50 round;
} */

/***** CERCADOR */

.cercadorPortada {
  background-color: #ffffffad;
  width: 80%;
  padding: 15px;
  position: relative;
  margin: 0 auto;
  top: 75px;
  text-align: center;
}

div#widget_cercador {
  max-width: 500px;
}

@media (max-width: 768px) {
  .cercadorPortada {
    top: 25px;
  }
}

.cercadorPortada h2 {
  color: var(--color-primary);
  text-align: left;
  margin-bottom: 50px;
}

/*PEU PÀGINA*/
.peu {
  background-color: var(--color-primary);
  color: #FFFFFF;
  padding-bottom: 10px;
}



.columnaPeu {
  height: 200px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.columnaPeuDreta {
  margin-top: 35px;
}

.columnaPeu p{
  margin:0;
}

@media (max-width: 768px) {
  .columnaPeu p, .columnaPeu ul{
    text-align: center;
  }
}

/*RESULTATS*/
.liniaRespostaItinerari:hover{
  background-color: var(--seleccio-hover);
}

.liniaRespostaItinerari {
  background-color: #f1f1f1;
}

.liniaRespostaItinerari.itinerariSeleccionat {
  background-color: var(--seleccionat);
}

.acordioResultat {
  text-align: left;
}

.acordioResultat .accordion .card .card-header {
  margin-bottom: 0px;
}
.acordioResultat .accordion .card {
  border-bottom-color: rgba(0, 0, 0, 0.125)!important;
    border-bottom-style: solid!important;
    border-bottom-width: 1px!important;
}

.resultats, .modulInferior, .modulPagina {
  margin: 30px 0;
}


.mapaSuperior {
  height: 300px;
  margin-bottom: 30px;
}

.siglesLiniaResultat .siglesLinia {
  display: inline-block;
  margin-right: 5px;
}

.childInlineBlock > div {
  display: inline-block;
}

.detallExpedicio > div {
  display: table-cell;
}

.colorliniaExpedicio {
  width: 7px;
}

.dadesExpedicio {
  padding-left: 10px;
}

.horaOrigenExpedicio, .horaDestinacioExpedicio, .dadesDireccioExpedicio {
  font-weight: 700;
}

.dadesRecorregutExpedicio {
  padding: 25px 10px;
}

.paradaOrigenExpedicio, .paradaDestinacioExpedicio, .dadesServeiExpedicio {
  padding-left: 5px;
}

.quadre {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 20px;
  margin-bottom: 20px;
}


.detallItinerari {
  margin-bottom: 15px;
}

.detallResultat {
  padding: 10px 0;
}

.modificaItinerariLink {
  background-color: var(--color-primary);
  color: #FFF;
  padding: 5px 10px;
  display: inline-block;
  margin: 10px 0px;
  border-radius: 6px;
}

.dadesItinerari.cap {
  margin: 50px 0;
}

.dadesItinerari.cap h2, .dadesItinerari.cap p {
  text-align: center;
}

.paradaRecorregut p {
  font-size: 16px;
}

.duradaItinerari p {
  font-size: 15px;
}

@media (max-width: 768px) {
  .duradaItinerari p {
    text-align: center;
}
}

@media (max-width: 768px) {
  .separacioColumna {
    margin-top: 20px;
}
}
.paradesRecorregut {
  margin: 10px;
}

.divTableCell.imgConnexio {
  max-width: 160px;
  width: 160px;
}

.divTableCell.horaOrigen {
  text-align: right;
  font-weight: 700;
  font-size: 26px;
}

.divTableCell.siglesLiniaResultat {
  text-align: center;
}

.divTableRow.separadorFiles {
  height: 10px;
}

.grupFiles {
  display: contents;
}

.missatgeTransbord {
  display: block!important;
  margin: 20px 0px;
  padding: 10px;
  background-color: #f1f1f1;
}

p.modificarItinerari {
  margin-top: 10px;
  font-size: 15px;
  color:  var(--color-primary);
}

/*Codi Linia*/
.siglesLinia {
  width: 85px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  font-weight: 800;
}

.noVisible {
  display: none;
}

.visible {
  display: initial;
}

.arxiu .icona {
  margin-right: 5px;
}

/*Pàgina Parada*/
.horaPasExpedicio {
  font-weight: 700;
}

.notificacioAvisos {
  display: inline-flex;
}


/*Pàgina Línia*/

.recorregutParadaColor {
  width: 20px;
  height: 35px;
  margin-bottom: -13px;
  text-align: center;
}

.paradaIniciLinia {
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
}

.paradaFinalLinia {
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
}

.taulaHorari tbody tr td {
  padding: 3px;
  text-align: center;
}

.icona-lectius {
  margin-left: 5px;
}


.botoTancarMapa {
  position: absolute;
  right: 14px;
  z-index: 1;
  margin-top: 57px;
}

/*Pàgina Expedició*/

.nomLiniaSuperior div {
  display: inline;
}

.nomLiniaSuperior .nomLinia {
  margin-left: 10px;
}

.nomLiniaSuperior {
  margin-bottom: 20px;
}

img.posicioActual {
  margin: 3px -5px;
}

/*Codi Taules*/

.divTable{
	display: table;
	width: 100%;
}
.divTableRow {
	display: table-row;
}
.divTableHeading {
	background-color: #EEE;
	display: table-header-group;
}
.divTableCell, .divTableHead {
	border: none;
	display: table-cell;
	padding: 3px 10px;
}
.divTableHeading {
	background-color: #EEE;
	display: table-header-group;
	font-weight: bold;
}
.divTableFoot {
	background-color: #EEE;
	display: table-footer-group;
	font-weight: bold;
}
.divTableBody {
	display: table-row-group;
}


/*MAPA GRAN*/

.interiorAcordio {
  max-height: 300px;
  overflow-y: auto;
}

.paradaLlista {
  padding: 5px;
}

.paradaLlista:hover {
  background-color: var(--seleccio-hover);
}

.paradaLlista div {
  display: table-cell;
  padding: 3px;
}

.accordioCapes {
  margin-bottom: 20px;
}

.iconaLinia .siglesLinia {
  padding: 0px 7px;
}

.botoMenuCapes {
  position: relative;
  top: -47px;
  left: 8px;
}

.capMenuCapes div, .capMenuCapes h3 {
  display: inline-block;
}

.capMenuCapes .botoTancar {
  position: absolute;
  right: 30px;
}

/*AVISOS*/

.avisHeader div {
  display: inline;
}

.finestraFlotantAvisosUsuari {
  max-width: 800px;
  /* max-height: 400px; */
}

.notaParadaDiferent {
  font-size: 14px;
  display: block!important;
}

.seleccionarIncidencia {
  border: 1px solid #b3b3b3;
  padding: 60px 10px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px #888888;
  max-width: 250px;
  max-height: 250px;
  width: 100%;
  cursor: pointer;
}
.dropdown-menu {
  max-height: 190px;
  overflow-y: auto;
}

.llistaAvisosUsuari {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .seleccionarIncidencia {
    padding: 15px 10px;
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 95px;
  }

  .etiquetaIncidencia {
    position: relative;
    top: 30px;
    line-height: 20px;
    line-height: 20px;
}


}

.seleccionarIncidencia:hover {
  background-color: var(--seleccio-hover);
}

.incidenciaSeleccionada {
  background-color: var(--seleccionat);
}

.incidenciaSeleccionada:hover {
  background-color: var(--seleccionat);
}

.seleccionarIncidencia div {
  text-align: center;
}

.formulariNouAvisUsuari > div {
  margin-bottom: 10px;
}


/*PÀGINA PARADA*/

.taulaTitolDia {
  width: 150px;
}

.llistaDestinacio div:only-child {
  display: inline-block;
}

.tooltipHoraPas p {
  margin: 0;
  text-align: center;
  font-size: 16px;
}

.horaPasTaulaParada {
  display: inline-block;
  padding: 10px;
}
.embolcallTaulaHorariParada {
    margin: 15px 0;
}
.embolcallTaulaHorariParada .titol {
  font-size: 20px;
}

.embolcallTaulaHorariParada .titol .destinacio {
  font-weight: 700;
}

/* DATA PICKERS */

.bootstrap-datetimepicker-widget.dropdown-menu {
  max-height: 480px;
  padding: 15px;
}

.datepicker-days td {
  border: 1px #cacaca solid;
  border-radius: 2px;
  padding: 6px;
  cursor: pointer;
  text-align: center;
}

.day.old, .day.new {
  opacity: 0.5;
}

.day.weekend {
  color: var(--color-primary);
}

.day:hover, .datepicker-months span:hover, .datepicker-years span:hover, .datepicker-decades span:hover {
  background-color: var(--seleccio-hover);
}

.day.today {
  font-weight: 700;
  color: var(--color-secondary);
}

.day.active {
  background-color: var(--seleccionat);
}

.datepicker th {
  text-align: center;
}

.datepicker .prev, .datepicker .next {
  cursor: pointer;
  /* border: 1px solid #000; */
}

.datepicker-months span, .datepicker-years span, .datepicker-decades span {
  padding: 5px;
  display: inline-block;
  cursor: pointer;
}
/* ADMINISTRADOR */

.taulaElements, .dadesElement {
  margin: 20px 0px;
}

.estadisticaBloc {
  margin: 5px;
  padding: 15px;
  border: 1px solid #adadad;
  border-radius: 5px;
}

.estadisticaBloc p, .estadisticaBloc div {
 text-align: center;
}

.estadisticaNumero {
  font-weight: 600;
  font-size: 55px;
}

.menuAdministrador div {
  padding: 10px 2px;
}

.avisUsuariData {
  min-width: 110px;
}

/* DESPLEGABLES */

.blocDesplegable > div {
  display: inline;
}

.blocDesplegable {
  margin-bottom: 5px;
}

/*FORMULARI HORA PAS*/
.horaPasParada #react-datetime-bootstrap input {
  width: 100px;
}

.horaPasParada > div {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}

/*AVÍS GENERAL*/
.avisGeneral {
  background-color: #fffcab;
  padding: 25px 0px;
}

/*AVÍS GENERAL*/
.avisPeu {
  /* background-color: #fffcab; */
  background-color: var(--seleccio-hover);
  padding: 5px 0px;
}

.avisPeu p{
  color: #000;
  text-align: center;
  font-size: 12px;
}

/*TRANSPORT DEMANDA*/
.logoTAD {
  max-width: 130px;
  float: left;
  margin: 10px 20px 10px 0px;
}


/*ALTRE CODI CSS*/

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.text-petit {
  font-size: 12px;
}

/*Símbol carregant (loading.io)*/
.carregant {
  margin:0 auto;
  text-align: center;
}


.lds-ripple {
  margin: 50px 0;
  display: inline-block;
  position: relative;
  width: 300px;
  height: 300px;
  text-align: center;
}
.lds-ripple div {
  position: absolute;
  border: 8px solid var(--color-primary);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  text-align: center;
  
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
  text-align: center;
}
@keyframes lds-ripple {
  0% {
    top: 135px;
    left: 135px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 280px;
    height: 280px;
    opacity: 0;
  }
}
